<template>
  <div>

    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    <!-- Begin page -->
    <div id="layout-wrapper">
      <HorizontalTopbar />
      <HorizontalNav />
      <div class="page-content">
        <div class="container-fluid">
          <slot />
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <Footer/>
    </div>
    <!-- end main content-->
  </div>
  <!-- END layout-wrapper -->
</template>


<script>
import HorizontalNav from '../components/nav/HorizontalNav';
import HorizontalTopbar from '../components/nav/HorizontalTopbar';

export default {
  components: {HorizontalTopbar, HorizontalNav},
  data() {
    return {}
  },
  created: () => {
    document.body.setAttribute("data-layout", "horizontal");
  },
  mounted() {
    const layout = JSON.parse(localStorage.getItem("layout")) || {};
    if (layout.loader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function() {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  },
}
</script>