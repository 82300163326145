<template>
  <header id="page-topbar" :class="topBarClass">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="/img/icon.png" alt height="40"/>
              <span>Spirit 2.0</span>
            </span>
            <span class="logo-lg">
              <img src="/img/icon.png" alt height="40"/>
             <span>Spirit 2.0</span>
            </span>
          </a>

          <a href="/" class="logo logo-light">
           <span class="logo-sm">
              <img src="/img/icon.png" alt height="40"/>
                <span>Spirit 2.0</span>
            </span>
            <span class="logo-lg">
              <img src="/img/icon.png" alt height="40"/>
                 <span>Spirit 2.0</span>
            </span>
          </a>
        </div>
        <div class="instance-selector">
          <v-select :options="instances" v-model="selectedInstance" label="label" value="value"
                    @input="selectInstance"/>
        </div>
        <button
            id="toggle"
            type="button"
            class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
            @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>


      </div>

      <div class="d-flex">
        <b-dropdown
            class="d-inline-block d-lg-none ml-2"
            variant="black"
            menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
            toggle-class="header-item noti-icon"
            right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <b-dropdown
            right
            variant="black"
            toggle-class="header-item"
            menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
          <span class="d-none d-xl-inline-block ml-1">
              {{ username }}
          </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->

          <b-dropdown-divider></b-dropdown-divider>
          <a href="/logout" class="dropdown-item text-danger" @click.prevent="logOut">
            <i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            Logout
          </a>
          <span class="app-version">Version: {{ appVersion }}</span>
        </b-dropdown>


      </div>
    </div>
  </header>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      appVersion: null,
      instances: [
        {
          label: "Beverages",
          value: 'Beverages',
          code: 'beverages'
        },
        {
          label: "Health & Beauty",
          value: 'Health & Beauty',
          code: 'hnb'
        }
      ],
      selectedInstance: null,
      topBarClass: null,
    };
  },
  mounted() {
    this.appVersion = process.env.APP_VERSION ?? '1.0';
    this.fetchInstance();
  },
  computed: {
    username() {
      return this.$store.state.auth.user.user_full_name
    },
    instancesAllowed() {
      return this.$store.state.auth.user.instances ?  this.$store.state.auth.user.instances : 'beverages'
    }

  },
  methods: {
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    fetchInstance() {
      const key = 'spirit2Instance';

      if (localStorage.getItem(key) === null) {
        return this.selectInstance();
      }

      const localStorageItem = localStorage.getItem(key);

      try {
        this.selectedInstance = JSON.parse(localStorageItem);

        if (!  this.instancesAllowed.includes(this.selectedInstance.code)) {
          this.selectedInstance = null;
          localStorage.removeItem(key);
          this.selectInstance();
        }

        this.applyClass();
      } catch (e) {
        this.selectInstance()
      }
    },
    selectInstance() {

      this.instances.forEach((_, key) => {
        if (!this.instancesAllowed.includes(_.code)) {
          delete this.instances[key];
        }
      })

      if (this.selectedInstance === null) {
        this.selectedInstance = this.instances[0];
      }

      localStorage.setItem('spirit2Instance', JSON.stringify(this.selectedInstance));

      this.applyClass();
      window.location.reload();

    },

    applyClass() {
      this.topBarClass = this.selectedInstance.code
    }

  },
};
</script>

<style lang="scss">
.app-version {
  font-size: 12px;
  display: block;
  text-align: center;
  padding: 10px 0;
  color: #999;
}

#page-topbar {

  &.hnb {
    background-color: #255430 !important;
  }

}

.instance-selector {
  display: flex;
  align-items: center;

  .v-select {
    cursor: pointer;
  }

  .vs__selected-options {
    flex-wrap: nowrap;
    min-width: 140px;
  }

  .vs__selected {
    color: #fff;
  }

  .vs__dropdown-toggle {
    border: solid 1px #fff;
  }

  .vs__clear {
    display: none;
  }

  svg {
    fill: #fff;
  }

}
</style>